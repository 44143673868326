import process from 'process';
const env = process['env'];
// console.log(env)

export const InputPosition = {
    TOP: 'top',
    BOTTOM: 'bottom',
}

export const periodStatusDisplay = {
    SCHEDULED: {
        icon: 'calendar',
        color: 'orange',
        text: 'Belum buka',
    },
    OPEN: {
        icon: 'door-open',
        color: 'green',
        text: 'Buka',
    },
    CLOSED: {
        icon: 'door-closed',
        color: 'green',
        text: 'Tutup',
    },
    PUBLISHED: {
        icon: 'eye',
        color: 'green',
        text: 'Sudah dirilis',
    },
    CANCELLED: {
        icon: 'ban',
        color: 'red',
        text: 'Batal',
    },
    [null]: {
        icon: 'ban',
        color: 'red',
        text: 'Batal',
    },
}

export const betStatusDisplay = {
    ACTIVE: {
        icon: 'calendar',
        color: 'primary',
        text: 'Terpasang',
    },
    WON: {
        icon: 'check',
        color: 'success',
        text: 'Menang',
    },
    LOST: {
        icon: 'times',
        color: 'danger',
        text: 'Kalah',
    },
    CANCELLED: {
        icon: 'ban',
        color: 'danger',
        text: 'Dibatalkan',
    },
    MASSCANCELLED: {
        icon: 'ban',
        color: 'danger',
        text: 'Dibatalkan',
    },
}

const isClient = typeof window !== 'undefined';
export const runtimeConfig =
{
    ...(isClient
        ? {
            // client
            // source: 'window',
            // env: window.env,
            apiUrl: window.env.RAZZLE_API_URL,
        }
        : {
            // server
            // source: 'process',
            // env: env || process.env,
            apiUrl: env.RAZZLE_SSR_API_URL || process.env.RAZZLE_SSR_API_URL
        }),
    appTitle: env.RAZZLE_APP_TITLE || 'AngkaSusun',
    // top, bottom
    inputPosition: (isClient ? window.env.RAZZLE_INPUT_POSITION : env.RAZZLE_INPUT_POSITION) || InputPosition.TOP,
};