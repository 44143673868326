import React, { useMemo, useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux';
import { Col, Row, Label, InputGroup, InputGroupAddon, Input, Button } from 'reactstrap';
import NumberFormat from 'react-number-format';

const isClient = typeof window != 'undefined';
const isVirtualKeyboardSupported = isClient && 'virtualKeyboard' in navigator

const TestBottomGrid = function ({ ...props }) {

    const testKeyboardInput = useRef()

    const [isOverlayContent, setIsOverlayContent] = useState(isClient && navigator?.virtualKeyboard?.overlaysContent || false)

    useEffect(() => {
        if (typeof window != 'undefined' && window.navigator?.virtualKeyboard) {
            const prev = window.navigator.virtualKeyboard.overlaysContent;
            window.navigator.virtualKeyboard.overlaysContent = false; // ONLY WORKS USING CHROME??

            return () => {
                window.navigator.virtualKeyboard.overlaysContent = prev; // ONLY WORKS USING CHROME??
            }
        }
    }, [])

    return (
        <div className="flex-grow-1 overflow-auto" style={{ display: 'grid', gridTemplate: '1fr 120px / 1fr', rowGap: 5 }}>
            <div className="flex-grow-1 d-flex flex-column overflow-auto mh-100 h-100">
                <fieldset className="flex-grow-1 d-flex flex-column overflow-auto mh-100 h-100">
                    <div>
                        Virtual keyboard is {isVirtualKeyboardSupported
                            && <span className="text-success font-weight-bold">Supported</span>
                            || <span className="text-danger font-weight-bold">Not Supported</span>}
                        <Row>
                            <code className="d-block col" style={{ whiteSpace: 'pre-line' }}>
                                {`LAYOUT VIEWPORT:
                                Height: ${isClient && document.body?.clientHeight?.toFixed(2)}
                                Width: ${isClient && document.body?.clientWidth?.toFixed(2)}`}
                            </code>
                        </Row>
                    </div>
                    <input ref={testKeyboardInput} inputMode="numeric" maxLength={1}></input>
                    {isVirtualKeyboardSupported
                        && <Label>
                            <input type="checkbox" checked={isOverlayContent} onChange={event => {
                                setIsOverlayContent(event.target.checked)
                                navigator.virtualKeyboard.overlaysContent = event.target.checked
                                testKeyboardInput.current.focus()
                            }} /> navigator.virtualKeyboard.overlaysContent
                        </Label>
                    }
                    {!isOverlayContent
                        && <select className="custom-select" onChange={(event) => {
                            const metaViewport = document.querySelector('meta[name="viewport"');
                            metaViewport.content = metaViewport.content.replace(/(, )?interactive-widget=[\w-]*/, '') + ', interactive-widget=' + event.target.value
                            testKeyboardInput.current.focus()
                        }}>
                            <option value="resizes-visual">resizes-visual</option>
                            <option value="resizes-content">resizes-content</option>
                            <option value="overlays-content">overlays-content</option>
                        </select>
                    }
                </fieldset>
            </div>
            <Row className="py-2 flex-column bg-dark">
                <Col xs className="mb-1">
                    <Row noGutters>
                        <Col className="mr-1">
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">Bayar: </InputGroupAddon>
                                <NumberFormat customInput={Input} autoComplete="off" aria-autocomplete="off" value={0} thousandSeparator={true} readOnly
                                    decimalScale={2} className="text-right text-gold font-weight-bold" />
                            </InputGroup>
                        </Col>
                        <Col xs="auto">
                            <Button color="gradient-primary" className="border-gold text-gold">Simpan</Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs className=" text-center">
                    {/* <h4 className="font-weight-bold text-gold">Rangkuman Taruhan</h4> */}
                    <h4 className="text-gold">
                        <span className="text-gold weight-bold">{0}</span> nomor,
                        bayar <NumberFormat displayType="text" value={0} thousandSeparator={true} readOnly
                            decimalScale={2} className="text-gold weight-bold" />
                    </h4>
                </Col>
                <Col xs className="">
                    <Button color="gradient-gold" size="lg" block className="font-weight-bold">
                        Pasang
                        {/* {readyTotals.count && <Badge color="success" className="ml-1">{readyTotals.count}</Badge> || ''} */}
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default connect(
    (state) => ({
    }), // Selects which state properties are merged into the component's props
    {}
)(TestBottomGrid);
