import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import { MatchMediaBreakpoints } from 'react-hook-breakpoints';

const bootstrapBreakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
};

const breakpoints = {
    mobile: 0,
    web: 576,
};

export default (props) => (
    <React.Fragment>
        <MatchMediaBreakpoints breakpoints={breakpoints}>
            <div className="d-flex d-sm-block flex-column h-100 overflow-y-auto overflow-x-hidden">
                <NavMenu />
                <Container size="sm" className="flex-grow-1 d-flex d-sm-block flex-column mx-auto overflow-y-auto overflow-x-hidden">
                    {props.children}
                </Container>
            </div>
        </MatchMediaBreakpoints>
    </React.Fragment>
);
