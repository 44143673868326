import React, { useEffect, useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Spinner } from 'reactstrap';
// import { Link } from 'react-router';
import './NavMenu.css';
import AuthStore from '../store/Auth';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { Link, useLocation } from 'react-router-dom';

function NavMenu({ auth, play, ...props }) {
    const [isOpen, toggleIsOpen] = useState(false);

    const toggle = () => {
        toggleIsOpen(!isOpen)
    }

    const { pathname: path } = useLocation()
    useEffect(() => {
        toggleIsOpen(false)
    }, [path])

    // console.log(auth.jwt)

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm border-b box-shadow mb-3 navbar-dark bg-gradient-primary py-0 py-sm-2">
                <Container className="flex">
                    <div>
                        {/* <NavbarBrand href="/" className="d-block d-sm-none">AS</NavbarBrand> */}
                        <Link to="/" className="navbar-brand">TOGEL</Link>
                    </div>

                    <div className="navbar-text d-block d-sm-none text-right flex-grow-1 pr-3">
                        {auth.isAuthenticated && (
                            <>
                                <div>{auth.user?.name}</div>
                            {play.currency} {play.balance === null
                                    ? <Spinner size="sm" color="gold"></Spinner>
                                    : <NumberFormat className="text-gold" value={Math.floor(play.balance)} displayType="text" thousandSeparator={true}></NumberFormat>}
                            </>
                        )}
                    </div>
                    <NavbarToggler onClick={toggle} />

                    <Collapse className="flex" isOpen={isOpen} navbar>
                        <ul className="navbar-nav">
                            {auth.isAuthenticated ? (
                                <>
                                    {/* <NavItem>
                                        <NavLink active={path == '/'} tag={Link} to="/">Home</NavLink>
                                    </NavItem> */}
                                    <NavItem>
                                        <NavLink active={path.startsWith('/play')} tag={Link} to="/play">Games</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={path.startsWith('/invoice')} tag={Link} to="/invoice">Invoice</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={path.startsWith('/transaction')} tag={Link} to="/transaction">Transaksi</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={path.startsWith('/bukumimpi')} tag={Link} to="/bukumimpi">Buku Mimpi</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={path.startsWith('/results')} tag={Link} to="/results">History Keluaran</NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink tag={Link} to="/logout">Logout</NavLink>
                                    </NavItem> */}
                                </>
                            ) : (
                                <></>
                                // <NavItem className="pull-right">
                                //     <NavLink tag={Link} to="/login">Login</NavLink>
                                // </NavItem>
                            )}
                        </ul>
                    </Collapse>
                    <div className="navbar-text text-right d-none d-sm-block p-0">
                        {auth.isAuthenticated && (
                            <>
                                {auth.user?.name} | {play.currency} {play.balance === null
                                    ? <Spinner size="sm" color="gold"></Spinner>
                                    : <NumberFormat className="text-gold" value={Math.floor(play.balance)} displayType="text" thousandSeparator={true}></NumberFormat>}
                            </>
                        )}
                    </div>
                </Container>
            </Navbar >
        </header >
    );

}

export default connect(
    (state) => ({ auth: state.auth, play: state.play }),
    { ...AuthStore.actionCreators }
)(NavMenu)