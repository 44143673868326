import http from '../http';


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestBets: (page, pageSize, force = false) => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const { transaction: appState } = getState();
        if (force || appState && page !== appState.page) {
            http.get(`tran/bets`, {
                params: {
                    page,
                    pageSize
                }
            })
                .then(({ data: { data, total } }) => {
                    dispatch({ type: 'RECEIVE_BETS', page, bets: data, totalSize: total });
                });

            dispatch({ type: 'REQUEST_BETS', page, pageSize });
        }
    },
    reloadBets: () => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const { transaction: { page, pageSize } } = getState();

        http.get(`tran/bets`, {
            params: {
                page,
                pageSize
            }
        })
            .then(({ data: { data, total } }) => {
                dispatch({ type: 'RECEIVE_BETS', page, bets: data, totalSize: total });
            });

        dispatch({ type: 'REQUEST_BETS', page, pageSize });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState = { bets: [], isLoading: false, page: 0, pageSize: 10, totalFiltered: 0, totalSize: 0 };

export const reducer = (state, incomingAction) => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction;
    switch (action.type) {
        case 'REQUEST_BETS':
            return {
                ...state,
                page: action.page,
                pageSize: action.pageSize,
                isLoading: true
            };
        case 'RECEIVE_BETS':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.page === state.page) {
                return {
                    ...state,
                    page: action.page,
                    totalSize: action.totalSize,
                    bets: action.bets,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};

const TransactionStore = { actionCreators, reducer };

export default TransactionStore